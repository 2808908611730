import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { DateBox, RadioGroup, TextBox, TextArea } from 'devextreme-react';
import { formatDate } from 'devextreme/localization';
import { DateTime } from 'luxon';
import { format } from '../../utlis/locale';
import { AuthContext } from '../../context/Auth.context';
import { postMedia, handleFileUpload } from '../../api/media';
import { Button } from '../../components/Button';
import CharacterCount from '../../components/CharacterCount';
import FieldLabel from '../../components/FieldLabel';
import Label from '../../components/Label';
import FileUploader from '../../components/FileUploader';
import TextLink from '../../components/TextLink';
import { withRouter } from '../../components/WithRouter';
import styles from './AddMediaPage.module.scss';

const VIDEO = 'video';
const DOC = 'doc';
const URL = 'mediaUrl';
const UPLOAD = 'upload';

const dataObj = {
    type: VIDEO,
    title: '',
    description: '',
    mediaUrl: '',
    videoSource: URL
};

const mediaTypes = [{ id: VIDEO, text: 'Video' }, { id: DOC, text: 'Presentation' }];
const videoSources = [{ id: URL, text: 'YouTube Link' }, { id: UPLOAD, text: 'File Upload' }];
const dateTimeLabel = { 'aria-label': 'Date Time' };
const nameLabel = { 'aria-label': 'Name' };
const messageLabel = { 'aria-label': 'Message' };
const TITLE_MAX_LENGTH = 100;
const DESCRIPTION_MAX_LENGTH = 255;
const URL_MAX_LENGTH = 255;

const AddMediaPage = ({ navigate }) => {
    const [authState] = useContext(AuthContext);
    const [mediaData, setMediaData] = useState({ ...dataObj, publishedDate: DateTime.local().toMillis(), publishedTime: DateTime.local().toMillis() });
    const [isEntitled, setEntitled] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successSubmit, setSuccessSubmit] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const user = authState.user;
    const isAuthenticated = authState.isAuthenticated;
    const isAuthor = user.author;
    const isVideoForm = mediaData.type === VIDEO;
    const isDocForm = mediaData.type === DOC;
    const isVideoSrcUrl = mediaData.videoSource === URL;
    const isVideoSrcUpload = mediaData.videoSource === UPLOAD;
    const allowedFileExtensions = isVideoForm ? ['video/mov', 'video/quicktime', 'video/mp4', 'audio/mpeg'] : ['application/pdf'];
    const maxFileSize = isVideoForm ? 104857600 : 52428800;
    const byteSize = format(maxFileSize, maxFileSize, 'byteSize');
    const fileUploaderSubTitle = isVideoForm ? `(.mp4, .mov, .mp3 only) - ${byteSize}` : `(.pdf only) - ${byteSize}`;

    useEffect(() => {
        if (isAuthenticated) {
            if (isAuthor) {
                setEntitled(true);
            }
        }
    }, [isAuthenticated, isAuthor]);

    useEffect(() => {
        if (!successSubmit) setMediaData({ ...dataObj, publishedDate: DateTime.local().toMillis(), publishedTime: DateTime.local().toMillis() });
    }, [successSubmit]);

    const redirectToListings = _ => {
        const newLocation = { ...location, pathname: location.pathname.replace('/add-media', '') };
        navigate(newLocation);
    };

    const formatTimeWithTimezone = (date) => {
        const formattedTime = formatDate(date, 'shortTime');
        const timezoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).formatToParts(date).find(part => part.type === 'timeZoneName').value;
        return `${formattedTime} ${timezoneName}`;
    };

    const handleValueChanged = e => {
        const name = e.component.option('name');
        const isType = name === 'type';
        const isVideoSrc = name === 'videoSource';
        const isPublishedDate = name === 'publishedDate';
        const isPublishedTime = name === 'publishedTime';
        const isTypeOrVideoSrc = isType || isVideoSrc;

        if (isPublishedDate && e.value === null || isPublishedTime && e.value === null) e.value = DateTime.local().toMillis();
        if (isTypeOrVideoSrc) {
            setSelectedFile(null);
        }

        setMediaData(prev => ({
            ...prev,
            description: isType ? '' : prev.description,
            mediaUrl: isTypeOrVideoSrc ? '' : prev.mediaUrl,
            [name]: e.value,
        }));
    };

    const handleBlur = e => {
        const name = e.component.option('name');
        setMediaData(prev => ({
            ...prev,
            [name]: prev[name].trim()
        }));
    };

    const handleCancelClick = _ => {
        redirectToListings();
    };

    const isValidForm = _ => {
        const emptyFields = [];

        if (!mediaData.title) emptyFields.push('title');
        if (!mediaData.publishedDate) emptyFields.push('publishedDate');
        if (!mediaData.publishedTime) emptyFields.push('publishedTime');
        if ((mediaData.type === VIDEO && mediaData.videoSource === URL) && !mediaData.mediaUrl) emptyFields.push('mediaUrl');
        if ((mediaData.type === VIDEO && mediaData.videoSource === UPLOAD) && !selectedFile) emptyFields.push('file');
        if ((mediaData.type === DOC || (mediaData.type === VIDEO && mediaData.videoSource === UPLOAD)) && !selectedFile) emptyFields.push('file');

        if (emptyFields.length > 0) {
            setValidationErrors(emptyFields);
            return false;
        }

        setValidationErrors([]);
        return true;
    };

    const handleSubmitClick = _ => {
        setIsSubmitting(true);
        setSubmitError(false);

        if (!isValidForm()) {
            setIsSubmitting(false);
            return;
        }

        // Parse the timestamps
        const dateTimeForDate = DateTime.fromMillis(mediaData.publishedDate);
        const dateTimeForTime = DateTime.fromMillis(mediaData.publishedTime);
        // Extract the date and time parts
        const datePart = dateTimeForDate.toFormat("yyyy-MM-dd");
        const timePart = dateTimeForTime.toFormat("HH:mm:ss");
        // Combine them into the desired format
        const isoString = `${datePart}T${timePart}`;

        const postParams = {
            compId: user.companyId,
            companyName: user.companyName,
            title: encodeURI(mediaData.title),
            description: encodeURI(mediaData.description),
            userId: user.id,
            userName: user.fullName,
            userEmail: user.email,
            mediaUrl: mediaData.mediaUrl,
            publishedTimestamp: DateTime.fromISO(isoString)
                .setZone('America/New_York')
                .toFormat('yyyy-MM-dd\'T\'HH:mm:ss')
        };

        if (!mediaData.mediaUrl) delete postParams.mediaUrl;
        if (!mediaData.description) delete postParams.description;

        if (selectedFile) {
            handleFileUpload(postParams, selectedFile)
                .then(data => {
                    setIsSubmitting(false);
                    setSuccessSubmit(true);
                    setMediaData({ ...dataObj, publishedDate: DateTime.local().toMillis(), publishedTime: DateTime.local().toMillis() });
                    setSelectedFile(null);
                })
                .catch(e => {
                    setIsSubmitting(false);
                    setSubmitError(e?.response?.data?.message?.replace('java.lang.IllegalArgumentException: ', '') || e?.message || 'Unable to submit media. Try again');
                });
        } else {
            postMedia(postParams)
                .then(data => {
                    setIsSubmitting(false);
                    setSuccessSubmit(true);
                    setMediaData({ ...dataObj, publishedDate: DateTime.local().toMillis(), publishedTime: DateTime.local().toMillis() });
                    setSelectedFile(null);
                })
                .catch(e => {
                    console.error(e);
                    setIsSubmitting(false);
                    setSubmitError(e?.response?.data?.message?.replace('java.lang.IllegalArgumentException: ', '') || e?.message || 'Unable to submit media. Try again');
                });
        }
    };

    const getUploadedPublishedDateTime = datetime => {
        const now = DateTime.now();
        const dateTimeToCheck = DateTime.fromISO(datetime);
        const isAfter = dateTimeToCheck > now;

        if (isAfter) {
            return 'PENDING';
        } else return 'ACTIVE';
    };

    return isEntitled ? <div className={styles.container}>
        {successSubmit ? <>
            <strong>
                Your Video/Media has been successfully submitted. It will be available on our websites a few minutes after your selected Publication Date/Time.
            </strong>
            <p>
                <TextLink onClick={_ => setSuccessSubmit(false)} text='Click here' /> to add another video or presentation.
            </p>
            <p>
                <TextLink onClick={redirectToListings} text='Click here' /> to go to the video/presentation listing.
            </p>
        </> : <>
            <h1>Add Video or Presentation</h1>
            <div>
                <div className='mtMed'>
                    <FieldLabel text='Media Type' />
                    <div className='mtSm'>
                        <RadioGroup
                            name='type'
                            items={mediaTypes}
                            value={mediaData?.type}
                            valueExpr='id'
                            disabled={isSubmitting}
                            displayExpr='text'
                            layout='horizontal'
                            onValueChanged={handleValueChanged}
                        />
                    </div>
                </div>
                <div className={cn(styles.gridCol2, 'mtMed')}>
                    <div>
                        <FieldLabel text='Publication Date' />
                        <DateBox
                            name='publishedDate'
                            defaultValue={DateTime.local().toMillis()}
                            inputAttr={dateTimeLabel}
                            type='date'
                            disabled={isSubmitting}
                            min={new Date()}
                            value={mediaData.publishedDate}
                            isValid={!validationErrors.includes('publishedDate')}
                            onValueChanged={handleValueChanged}
                        />
                    </div>
                    <div>
                        <FieldLabel text='Publication Time' />
                        <DateBox
                            name='publishedTime'
                            defaultValue={DateTime.local().toMillis()}
                            inputAttr={dateTimeLabel}
                            type='time'
                            disabled={isSubmitting}
                            value={mediaData.publishedTime}
                            displayFormat={formatTimeWithTimezone}
                            isValid={!validationErrors.includes('publishedTime')}
                            onValueChanged={handleValueChanged}
                        />
                    </div>
                </div>
                <div className='mtMed'>
                    <FieldLabel text='Title' />
                    <TextBox
                        name='title'
                        inputAttr={nameLabel}
                        placeholder='Title'
                        valueChangeEvent='keyup'
                        value={mediaData.title}
                        disabled={isSubmitting}
                        maxLength={TITLE_MAX_LENGTH}
                        isValid={!validationErrors.includes('title')}
                        onFocusOut={handleBlur}
                        onValueChanged={handleValueChanged}
                    />
                    <CharacterCount inputLength={mediaData.title.length} total={TITLE_MAX_LENGTH} />
                </div>
                {isVideoForm && <div className='mtMed'>
                    <FieldLabel text='Description' />
                    <TextArea
                        name='description'
                        height={100}
                        maxLength={DESCRIPTION_MAX_LENGTH}
                        inputAttr={messageLabel}
                        placeholder='Description'
                        disabled={isSubmitting}
                        valueChangeEvent='keyup'
                        value={mediaData.description}
                        onFocusOut={handleBlur}
                        onValueChanged={handleValueChanged}

                    />
                    <CharacterCount inputLength={mediaData.description.length} total={DESCRIPTION_MAX_LENGTH} />
                </div>}
                {isVideoForm && <>
                    <div className='mtMed'>
                        <FieldLabel text='Video Source' />
                        <div className='mtSm'>
                            <RadioGroup
                                name='videoSource'
                                items={videoSources}
                                value={mediaData?.videoSource}
                                valueExpr='id'
                                disabled={isSubmitting}
                                displayExpr='text'
                                layout='horizontal'
                                onValueChanged={handleValueChanged}
                            />
                        </div>
                    </div>
                    {(isVideoForm && isVideoSrcUrl) && <div className='mtMed'>
                        <FieldLabel text='Youtube LInk' />
                        <TextBox
                            name='mediaUrl'
                            inputAttr={nameLabel}
                            placeholder='http://www.youtube.com/watch?v='
                            valueChangeEvent='keyup'
                            value={mediaData.mediaUrl}
                            disabled={isSubmitting}
                            isValid={!validationErrors.includes('mediaUrl')}
                            maxLength={URL_MAX_LENGTH}
                            onFocusOut={handleBlur}
                            onValueChanged={handleValueChanged}
                        />
                        <CharacterCount inputLength={mediaData.mediaUrl.length} total={URL_MAX_LENGTH} />
                    </div>}
                </>}
                {(isVideoSrcUpload || isDocForm) && <div className='mtMed'>
                    <FieldLabel text='Upload File' />
                    <FileUploader
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        allowedFileExtensions={allowedFileExtensions}
                        isValid={!validationErrors.includes('file')}
                        maxFileSize={maxFileSize}
                        disabled={isSubmitting}
                        subTitle={fileUploaderSubTitle}
                    />
                </div>}
                {submitError && <Label className='mtLg' isError>{submitError}.</Label>}
                <div className={styles.controls}>
                    {isSubmitting && <span className={`${styles.loader} mrLg`}></span>}
                    <TextLink text='Cancel' inactive={isSubmitting} onClick={handleCancelClick} />
                    <Button className='mlLg' title='Submit' inactive={isSubmitting} onClick={handleSubmitClick} />
                </div>
            </div>
        </>
        }
    </div> : <></>;
};

AddMediaPage.propTypes = {
    navigate: PropTypes.func
};

export default withRouter(AddMediaPage);