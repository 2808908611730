import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './More.module.scss';

const More = ({ disabled, onClick }) => {
  const classes = cn(styles.more, { [styles.disabled]: disabled });

  return <div className={classes} onClick={disabled ? null : onClick}>
    More <FontAwesomeIcon className={styles.iconDown} icon={['fas', 'chevron-down']} />
  </div>;
};

More.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default More;
