import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import DataGrid, {
  Column
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { DateTime } from 'luxon';
import ReactPlayer from 'react-player/lazy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteMedia, getMedia } from '../../api/media';
import { AuthContext } from '../../context/Auth.context';
import { DeleteButton } from '../../components/DeleteButton';
import { Button } from '../../components/Button';
import { Label } from '../../components/Label';
import { TextLink } from '../../components/TextLink';
import { DisplayResults } from '../../components/DisplayResults';
import { Loading } from '../../components/Loading';
import { Outline } from '../../components/Outline';
import { More } from '../../components/More';
import Thumbnail from '../../components/Thumbnail';
import styles from './VideosTable.module.scss';

const VIDEO_TYPE = 'VIDEO';
const ASC = 'ASC';
const DESC = 'DESC';

const VideosTable = ({ accent, symbol, editable }) => {
  const dataGridRef = useRef(null);
  const [authState] = useContext(AuthContext);
  const [videosParams, setVideosParams] = useState(null);
  const [videosData, setVideosData] = useState(null);
  const [videosDataSet, setVideosDataSet] = useState(null);
  const [isReloading, setReloading] = useState(false);
  const [videoError, setVideoError] = useState(null);
  const [currentVideo, setCurrentVideo] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);
  const [deleteDialogVisible, setDialogVisible] = useState(false);
  const [dialogInactive, setDialogInactive] = useState(false);
  const [popupWidth, setPopupWidth] = useState('40vw');
  const [deleteVideo, setDeleteVideo] = useState(null);
  const [dialogError, setDialogError] = useState(null);
  const [successDelete, setSuccessDelete] = useState(false);
  const [sortDir, setSortDir] = useState(DESC);
  const compId = authState.user.companyId;
  const user = authState.user;
  const hasMorePages = videosData?.totalPages > videosData?.currentPage;

  useEffect(() => {
    setVideosParams({ compId, type: VIDEO_TYPE });
  }, [symbol]);

  useEffect(() => {
    if (videosParams !== undefined && videosParams !== null) loadVideos();
  }, [videosParams]);

  useEffect(() => {
    updatePopupWidth(); // Set initial width
    window.addEventListener('resize', updatePopupWidth); // Update width on resize
    return () => window.removeEventListener('resize', updatePopupWidth);
  }, []);

  const updatePopupWidth = () => {
    if (window.innerWidth <= 1200) {
      setPopupWidth('80vw');
    } else {
      setPopupWidth('40vw');
    }
  };

  const loadVideos = _ => {
    if (!!videosData) setReloading(true);

    getMedia(videosParams)
      .then(data => {
        const newData = data.results;
        const isPageCall = videosParams?.page && videosParams.page !== 1;

        setReloading(false);
        setVideosData(data);
        setVideosDataSet(prevDataSet => {
          return isPageCall ? [...prevDataSet, ...newData] : newData;
        });
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          setErrorPage(true);
          return;
        }

        setReloading(false);
        setVideoError(error?.response?.data?.error || 'Error fetching Videos.');
      });
  };

  const showVideo = useCallback(
    (rowItem) => {
      setCurrentVideo(rowItem);
      setPopupVisible(rowItem);
    },
    [setCurrentVideo, setPopupVisible],
  );

  const hideInfo = useCallback(() => {
    setCurrentVideo({});
    setPopupVisible(false);
  }, [setCurrentVideo, setPopupVisible]);

  const sortStringsConsideringCulture = _ => {
    return;
  };

  const tableColumns = [
    {
      caption: 'Video',
      allowSorting: false,
      cellRender: item => {
        const isClickable = item.data.status === 'ACTIVE' || item.data.status === 'PENDING';

        return <div style={{ cursor: isClickable ? 'pointer' : 'not-allowed' }} onClick={_ => isClickable ? handleVideoClick(item.data) : null}>
          <Thumbnail imageSrc={item?.data?.thumbnailUrl} type='video' altText={item?.data?.title} iconColor={accent} />
        </div>;
      }
    },
    {
      dataField: 'title',
      sortingMethod: sortStringsConsideringCulture,
      cellRender: item => {
        const isClickable = item.data.status === 'ACTIVE' || item.data.status === 'PENDING';

        return <div className={cn(styles.colWrap, styles[accent], {
          [styles.link]: isClickable
        })} onClick={_ => isClickable ? handleVideoClick(item.data) : null}>{item.value}</div>;
      }
    },
    {
      dataField: 'status',
      allowSorting: false,
      cellRender: item => {
        let statusValue = item.value;

        if (statusValue === 'SCANNING') statusValue = 'PROCESSING';
        if (statusValue === 'PENDING') statusValue = 'SCHEDULED';
        if (statusValue === 'HOLD' || statusValue === 'BLOCKED') statusValue = 'DENIED';

        return <div className={styles[`status${statusValue}`]}>{statusValue}</div>;
      },
      visible: editable
    },
    {
      caption: 'Published',
      dataField: 'time',
      sortingMethod: sortStringsConsideringCulture,
      cellRender: item => <>{DateTime.fromISO(item.data.publishedTimestamp)
        .toLocal()
        .toFormat(editable ? 'LLL dd, yyyy hh:mm a ZZZZ' : 'LLL dd, yyyy')}<br />{editable && item.data.createdBy}</>
    },
    {
      caption: 'Action',
      cellRender: item => <DeleteButton title={item.data.title} accent={accent} onDeleteClick={_ => handleDeleteShow(item.data)} />,
      visible: editable
    }
  ];

  useEffect(() => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearSorting();
    }
  }, [symbol]);

  const handlePreparing = e => {
    if (e.target === 'header') {
      e.target.visible = false;
    }
  };

  const handleCellClick = e => {
    if (e?.rowType === 'header' && (e?.column?.dataField === 'title' || e?.column?.dataField === 'time')) {
      const sortBy = e.column.dataField.toUpperCase();
      let sort = ASC;
      if (videosParams?.sortBy === sortBy && videosParams.sortOn === ASC) sort = DESC;
      setSortDir(sort);
      setVideosParams({ ...videosParams, page: 1, sortOn: sort, sortBy });
    }
  };

  const handleVideoClick = item => {
    showVideo(item);
  };

  const handleMoreClick = _ => {
    setVideosParams(prevData => {
      return { ...prevData, page: videosData.currentPage + 1 };
    });
  };

  const handleDeleteShow = data => {
    setDialogVisible(true);
    setDeleteVideo(data);
  };

  const handleDeleteHide = _ => {
    setDialogVisible(false);
    if (successDelete) {
      setVideosParams({ compId, type: VIDEO_TYPE });
      loadVideos();
    }
  };

  const handleDeleteHidden = _ => {
    if (successDelete) {
      setSuccessDelete(false);
    }
  };

  const handleConfirmDeleteVideo = _ => {
    const deleteParams = {
      id: deleteVideo.id,
      userName: user.fullName
    };

    setDialogError(null);
    setDialogInactive(true);

    deleteMedia(deleteParams)
      .then(_ => {
        setDialogInactive(false);
        setSuccessDelete(true);
      })
      .catch(e => {
        setDialogInactive(false);
        setDialogError('Error deleting file. Try again.');
      });
  };

  return <div className={styles.container}>
    <Outline mode='heading' accent={accent}>
      Videos
    </Outline>
    <Loading loaded={!!videosDataSet} reloading={isReloading} error={videoError}>
      <DataGrid
        className={`${videosDataSet?.length === 0 ? 'hide-scroll' : ''}`}
        onContextMenuPreparing={handlePreparing}
        ref={dataGridRef}
        dataSource={videosDataSet}
        paging={{ enabled: false }}
        columnAutoWidth
        onCellClick={handleCellClick}
        loadPanel={{ enabled: false }}
        noDataText='No videos available.'
      >
        {tableColumns.map((column, i) => (
          <Column key={`column${i}`} {...column} />
        ))}
      </DataGrid>
      <More onClick={handleMoreClick} disabled={!hasMorePages} />
      {videosDataSet?.length > 0 && <DisplayResults show={videosDataSet?.length || 0} total={videosData?.totalResults} text={'Videos'} />}
    </Loading>
    <Popup
      visible={popupVisible}
      dragEnabled={false}
      title={currentVideo.title}
      hideOnOutsideClick
      showCloseButton
      position='center'
      width='65vw'
      height='auto'
      onHiding={hideInfo}
    >
      <div className={styles.videoContainer}>
        <ReactPlayer
          className={styles.video}
          url={currentVideo.mediaUrl}
          width='100%'
          height='100%'
          playing
          controls
        />
      </div>
    </Popup>
    <Popup
      visible={deleteDialogVisible}
      dragEnabled={false}
      title={deleteVideo?.title}
      hideOnOutsideClick
      showCloseButton
      position='center'
      width={popupWidth}
      height='auto'
      onHidden={handleDeleteHidden}
      onHiding={handleDeleteHide}>
      {successDelete ? <div>
        <div className={styles.confrmMsg}>
          <FontAwesomeIcon className='mrSm' icon={['far', 'check-circle']} />
          {deleteVideo?.title} has successfully been deleted.
        </div>
        <div className={styles.dialogControls}>
          <Button title='OK' onClick={_ => setDialogVisible(false)} />
        </div>
      </div> : <>
        <div>
          <h3>Are you sure you want to delete '{deleteVideo?.title}'?</h3>
        </div>
        {dialogError && <Label className='mbLg' isError>{dialogError}</Label>}
        <div className={styles.dialogControls}>
          <TextLink text='Cancel' inactive={dialogInactive} onClick={_ => setDialogVisible(false)} />
          <Button className='mlLg' title='Delete' inactive={dialogInactive} onClick={handleConfirmDeleteVideo} />
        </div></>}
    </Popup>
  </div>;
};

VideosTable.defaultProps = {
  accent: 'green',
};

VideosTable.propTypes = {
  accent: PropTypes.string,
  symbol: PropTypes.string,
  editable: PropTypes.bool
};

export default VideosTable;
