import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/Auth.context';
import { ThemeProvider } from './context/Theme.context';
import Layout from './components/Layout';
import AddMediaPage from './pages/AddMediaPage';
import MediaPage from './pages/MediaPage';
import ResearchReportPage from './pages/ResearchReportPage';
import AddResearchReport from './pages/AddResearchReport';
import NotFoundPage from './pages/NotFoundPage';
import { ROUTES } from './constants/routes';

const App = () => {
  return (
    <div className='otc-theme'>
      <ThemeProvider>
        <AuthProvider>
          <Routes>
            <Route path='*' element={<NotFoundPage />} />
            <Route path={ROUTES.media} element={<Layout><MediaPage /></Layout>} />
            <Route path={ROUTES.addMedia} element={<Layout><AddMediaPage /></Layout>} />
            <Route path={ROUTES.researchReport} element={<Layout><ResearchReportPage /></Layout>} />
            <Route path={ROUTES.addResearchReport} element={<Layout><AddResearchReport /></Layout>} />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
