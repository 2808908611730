import React, { useContext, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import DataGrid, {
  Column
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { deleteMedia, getMedia } from '../../api/media';
import { AuthContext } from '../../context/Auth.context';
import { DeleteButton } from '../../components/DeleteButton';
import { DisplayResults } from '../../components/DisplayResults';
import { Loading } from '../../components/Loading';
import { TextLink } from '../../components/TextLink';
import { Button } from '../../components/Button';
import { Label } from '../../components/Label';
import { Outline } from '../../components/Outline';
import { More } from '../../components/More';
import styles from './DocsTable.module.scss';

const DOC_TYPE = 'DOC';
const ASC = 'ASC';
const DESC = 'DESC';

const DocsTable = ({ accent, symbol, editable }) => {
  const dataGridRef = useRef(null);
  const [authState] = useContext(AuthContext);
  const [docsParams, setDocsParams] = useState(null);
  const [docsData, setDocsData] = useState(null);
  const [docsDataSet, setDocDataSet] = useState(null);
  const [isReloading, setReloading] = useState(false);
  const [docsError, setDocsError] = useState(null);
  const [deleteDialogVisible, setDialogVisible] = useState(false);
  const [dialogInactive, setDialogInactive] = useState(false);
  const [popupWidth, setPopupWidth] = useState('40vw');
  const [deleteDoc, setDeleteDoc] = useState(null);
  const [dialogError, setDialogError] = useState(null);
  const [successDelete, setSuccessDelete] = useState(false);
  const [sortDir, setSortDir] = useState(DESC);
  const compId = authState.user.companyId;
  const user = authState.user;
  const hasMorePages = docsData?.totalPages > docsData?.currentPage;

  useEffect(() => {
    setDocsParams({ compId, type: DOC_TYPE });
  }, [symbol]);

  useEffect(() => {
    if (docsParams !== undefined && docsParams !== null) loadDocs();
  }, [docsParams]);

  useEffect(() => {
    updatePopupWidth(); // Set initial width
    window.addEventListener('resize', updatePopupWidth); // Update width on resize
    return () => window.removeEventListener('resize', updatePopupWidth);
  }, []);

  const updatePopupWidth = () => {
    if (window.innerWidth <= 1200) {
      setPopupWidth('80vw');
    } else {
      setPopupWidth('40vw');
    }
  };

  const handleDeleteShow = data => {
    setDialogVisible(true);
    setDeleteDoc(data);
  };

  const handleDeleteHide = _ => {
    setDialogVisible(false);
    if (successDelete) {
      setDocsParams({ compId, type: DOC_TYPE });
      loadDocs();
    }
  };

  const handleDeleteHidden = _ => {
    if (successDelete) {
      setSuccessDelete(false);
    }
  };

  const handleConfirmDeleteDoc = _ => {
    const deleteParams = {
      id: deleteDoc.id,
      userName: user.fullName
    };

    setDialogError(null);
    setDialogInactive(true);

    deleteMedia(deleteParams)
      .then(_ => {
        setDialogInactive(false);
        setSuccessDelete(true);
      })
      .catch(e => {
        setDialogInactive(false);
        setDialogError('Error deleting file. Try again.');
      });
  };

  const loadDocs = _ => {
    if (!!docsData) setReloading(true);

    getMedia(docsParams)
      .then(data => {
        const newData = data.results;
        const isPageCall = docsParams?.page && docsParams.page !== 1;

        setReloading(false);
        setDocsData(data);
        setDocDataSet(prevDataSet => {
          return isPageCall ? [...prevDataSet, ...newData] : newData;
        });
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          setErrorPage(true);
          return;
        }

        setReloading(false);
        setDocsError(error?.response?.data?.error || 'Error fetching Presentations.');
      });
  };

  const sortStringsConsideringCulture = _ => {
    return;
  };

  const tableColumns = [
    {
      dataField: 'title',
      sortingMethod: sortStringsConsideringCulture,
      cellRender: item => {
        const isClickable = item.data.status === 'ACTIVE' || item.data.status === 'PENDING';

        return <div className={cn(styles.colWrap, styles[accent], {
          [styles.link]: isClickable
        })} onClick={_ => isClickable ? handleDocClick(item.data) : null}>{item.value}</div>;
      }
    },
    {
      dataField: 'status',
      allowSorting: false,
      cellRender: item => {
        let statusValue = item.value;

        if (statusValue === 'SCANNING') statusValue = 'PROCESSING';
        if (statusValue === 'PENDING') statusValue = 'SCHEDULED';
        if (statusValue === 'HOLD' || statusValue === 'BLOCKED') statusValue = 'DENIED';

        return <div className={styles[`status${statusValue}`]}>{statusValue}</div>;
      },
      visible: editable
    },
    {
      caption: 'Published',
      dataField: 'time',
      sortingMethod: sortStringsConsideringCulture,
      cellRender: item => <>{DateTime.fromISO(item.data.publishedTimestamp)
        .toLocal()
        .toFormat(editable ? 'LLL dd, yyyy hh:mm a ZZZZ' : 'LLL dd, yyyy')}<br />{editable && item.data.createdBy}</>
    },
    {
      caption: 'Action',
      cellRender: item => <DeleteButton title={item.data.title} accent={accent} onDeleteClick={_ => handleDeleteShow(item.data)} />,
      visible: editable
    }
  ];

  useEffect(() => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearSorting();
    }
  }, [symbol]);

  const handlePreparing = e => {
    if (e.target === 'header') {
      e.target.visible = false;
    }
  };

  const handleCellClick = e => {
    if (e?.rowType === 'header' && (e?.column?.dataField === 'title' || e?.column?.dataField === 'time')) {
      const sortBy = e.column.dataField.toUpperCase();
      let sort = ASC;
      if (docsParams?.sortBy === sortBy && docsParams.sortOn === ASC) sort = DESC;
      setSortDir(sort);
      setDocsParams({ ...docsParams, page: 1, sortOn: sort, sortBy });
    }
  };

  const handleDocClick = item => {
    window.open(`/media/content?symbol=${symbol}&id=${item.id}`);
  };

  const handleMoreClick = _ => {
    setDocsParams(prevData => {
      return { ...prevData, page: docsData.currentPage + 1 };
    });
  };

  return <div className={styles.container}>
    <Outline mode='heading' accent={accent}>
      Presentations
    </Outline>
    <Loading loaded={!!docsDataSet} reloading={isReloading} error={docsError}>
      <DataGrid
        className={`${docsDataSet?.length === 0 ? 'hide-scroll' : ''}`}
        onContextMenuPreparing={handlePreparing}
        ref={dataGridRef}
        dataSource={docsDataSet}
        paging={{ enabled: false }}
        columnAutoWidth
        onCellClick={handleCellClick}
        loadPanel={{ enabled: false }}
        noDataText='No presentations available.'
      >
        {tableColumns.map((column, i) => (
          <Column key={`column${i}`} {...column} />
        ))}
      </DataGrid>
      <More onClick={handleMoreClick} disabled={!hasMorePages} />
      {docsDataSet?.length > 0 && <DisplayResults show={docsDataSet?.length || 0} total={docsData?.totalResults} text={'Presentations'} />}
    </Loading>
    <Popup
      visible={deleteDialogVisible}
      dragEnabled={false}
      title={deleteDoc?.title}
      hideOnOutsideClick
      showCloseButton
      position='center'
      width={popupWidth}
      height='auto'
      onHidden={handleDeleteHidden}
      onHiding={handleDeleteHide}>
      {successDelete ? <div>
        <div className={styles.confrmMsg}>
          <FontAwesomeIcon className='mrSm' icon={['far', 'check-circle']} />
          {deleteDoc?.title} has successfully been deleted.
        </div>
        <div className={styles.dialogControls}>
          <Button title='OK' onClick={_ => setDialogVisible(false)} />
        </div>
      </div> : <>
        <div>
          <h3>Are you sure you want to delete "{deleteDoc?.title}"?</h3>
        </div>
        {dialogError && <Label className='mbLg' isError>{dialogError}</Label>}
        <div className={styles.dialogControls}>
          <TextLink text='Cancel' inactive={dialogInactive} onClick={_ => setDialogVisible(false)} />
          <Button className='mlLg' title='Delete' inactive={dialogInactive} onClick={handleConfirmDeleteDoc} />
        </div></>}
    </Popup>
  </div>;
};

DocsTable.defaultProps = {
  accent: 'green',
};

DocsTable.propTypes = {
  accent: PropTypes.string,
  symbol: PropTypes.string,
  editable: PropTypes.bool
};

export default DocsTable;
