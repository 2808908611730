import React from 'react';
import styles from './Header.module.scss';

const Header = () => <header className={styles.container}>
  <div className={styles.content}>
    <img src='/images/OTC_IQ_Logo.png' alt='OTC IQ Logo' />
  </div>
  <div className={styles.otcLine} />
</header>;

export default Header;
