import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import FieldLabel from '../FieldLabel';
import styles from './StaticValue.module.scss';

const StaticValue = ({ labelText, labelSize, value }) => {
  return <div>
    <FieldLabel text={labelText} size={labelSize} />
    <div className={cn(styles.staticVal)}>{value}</div>
  </div>;
};

StaticValue.propTypes = {
  labelText: PropTypes.string,
  labelSize: PropTypes.string,
  value: PropTypes.any

};

export default StaticValue;
