import axios from 'axios';
import queryString from 'query-string';
import { storage } from '../constants';
import { redirectIQ } from '../utlis/helper';

axios.interceptors.request.use(function (config) {
    // Serialize the parameteters
    config.paramsSerializer = params => queryString.stringify(params, { arrayFormat: 'brackets' });
    return config;
});

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.error('Session Error: ', error);
        if (error.response && error.response.status === 401) {
            redirectIQ();
        }
        
        return Promise.reject(error);
    }
);

export default {
    request: async (options) => {
        const symbol = options?.params?.symbol || window.sessionStorage.getItem(storage.SYMBOL) || null;
        
        if (options.isOTC) {
            if (!options.params) options.params = {};
            options.params.symbol = symbol.toUpperCase();
        }

        const response = await axios(options);
        return response;
    }
};
