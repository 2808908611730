import React from 'react';
import styles from './Footer.module.scss';
import { footerLinks } from '../../constants/footerLinks';

const Footer = () => <footer className={styles.container}>
  <a className={styles.linkItem} href='https://www.otcmarkets.com' target='_blank' rel='noopener noreferrer'>
    &copy; {new Date().getFullYear()} OTC Markets Group Inc.
  </a>
  {footerLinks.map(({ link, href, to }, i) =>
    <a key={i} href={href} to={to} className={styles.linkItem} target='_blank' rel='noopener noreferrer'>
      {link}
    </a>
  )}
</footer>;

export default Footer;
