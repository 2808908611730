import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/Auth.context';
import { ThemeContext } from '../../context/Theme.context';
import { Button } from '../../components/Button';
import { withRouter } from '../../components/WithRouter';
import ResearchReportsTable from '../../container/ResearchReportsTable';
import styles from './ResearchReportPage.module.scss';

const ResearchReportPage = ({ params }) => {
  const [themeState] = useContext(ThemeContext);
  const [authState] = useContext(AuthContext);
  const accentColor = themeState.color;
  const symbol = params?.symbol;
  const user = authState.user;
  const isAuthor = user.author;
  const isReady = themeState.isReady;

  return <div className={styles.container}>
    {isReady && <>
      {isAuthor && <div className={styles.btnContainer}>
        <Link to={ROUTES.addResearchReport.replace(':symbol', symbol)}>
          <Button title='Add Research Report' color={accentColor} />
        </Link>
      </div>}
      <div className='mtMed'>
        <ResearchReportsTable
          accent={accentColor}
          symbol={symbol}
          editable={isAuthor}
        />
      </div>
    </>}
  </div>;
};

ResearchReportPage.propTypes = {
  params: PropTypes.object
};

export default withRouter(ResearchReportPage);
