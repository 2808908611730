import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Outline = (props) => (<div>
  <div className={classNames(styles[props.accent || 'default'], props.className, {
    [styles.outline]: true,
    [styles.heading]: props.mode === 'heading',
    [styles.headingSmall]: props.mode === 'headingSmall',
    [styles.heading2]: props.mode === 'heading2',
    [styles.heading3]: props.mode === 'heading3',
    [styles.section1]: props.mode === 'section1',
    [styles.section2]: props.mode === 'section2',
    [styles.section3]: props.mode === 'section3',
    [styles.invisible]: props.mode === 'invisible',
    [styles.noSpacing]: props.spacing === 'none',
    [styles.largeSpacing]: props.spacing === 'large',
    [styles.largerSpacing]: props.spacing === 'larger',
    [styles.marginTop]: props.margin === 'top',
    [styles.marginBottom]: props.margin === 'bottom',
    [styles.noMargin]: props.margin === 'none',
    [styles.topBorderOnly]: props.topBorderOnly,
    [styles.bottomBorderOnly]: props.bottomBorderOnly
  })} >
    {(props.mode === 'heading' || props.mode === 'headingSmall') && props.children ? <div className={styles.text}>{props.children}</div> : props.children}
    {(props.filters || props.content || props.buttons) && <div className={styles.rightContent}>
      {props.content}
      {props.buttons && <div className={styles.buttons}>
        {props.buttons.map((button, i) => {
          return <div key={`button_${i}`}title={button.title} className={classNames(styles.button, {
            [styles.hideOnMobile]: button.hideOnMobile,
            [styles.hideOnTablet]: button.hideOnTablet
          })}>{button.icon}</div>;
        })}
      </div>}
    </div>}
  </div>
</div>);

Outline.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string.isRequired,
  /** Show only top border */
  topBorderOnly: PropTypes.bool,
  /** Show only bottom border */
  bottomBorderOnly: PropTypes.bool,
  /** Spacing within borders */
  spacing: PropTypes.string,
  margin: PropTypes.string,
  accent: PropTypes.string,
  children: PropTypes.node,
  /** Use of TableFilter component */
  filters: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['select', 'date']),
    title: PropTypes.string,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.oneOfType([
      /* string */
      PropTypes.string,
      /* complex object with title/value etc. */
      PropTypes.shape({
        /** Title/key of the item */
        title: PropTypes.string.isRequired,
        /** Item value */
        value: PropTypes.string.isRequired,
        /** Nested items (creates a submenu) */
        nested: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        }))
      })
    ])),
    className: PropTypes.string
  })),
  buttons: PropTypes.arrayOf(PropTypes.object),
  content: PropTypes.node
};

export default Outline;
