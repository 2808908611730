import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './BackButton.module.scss';

const BackButton = ({ className, to }) => {
  const navigate = useNavigate();

  const goBack = () => {
    to ? navigate(to) : navigate(-1); // Navigate back to the previous page
  };

  return <div className={`${[styles.container]} ${className}`} onClick={goBack}>
    <FontAwesomeIcon icon={['fas', 'arrow-left-long']} />
    <span>Back</span>
  </div>;
};

BackButton.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string
};

export default BackButton;
