import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { storage } from '../../constants';
import { AuthContext } from '../../context/Auth.context';
import { withRouter } from '../WithRouter';
import CompanyHeader from '../../container/CompanyHeader';
import Header from '../Header';
import Footer from '../Footer';
import styles from './Layout.module.scss';
import NotFound from '../NotFound';

const Layout = ({ params, children, location, navigate }) => {
  const [authState, authDispatch] = useContext(AuthContext);
  const isAuthenticated = authState.isAuthenticated;
  const invalidSymbol = authState.invalidSymbol;
  const user = authState.user;
  const isAuthor = user.author;
  const { symbol } = params;

  useEffect(() => {
    window.sessionStorage.setItem(storage.SYMBOL, symbol);

    authDispatch({
      type: 'SET_SYMBOL',
      payload: symbol
    });
  }, [symbol]);

  useEffect(() => {
    if (isAuthenticated) {
      const isAddMedia = location.pathname.includes('/add-media');
      const isAddResearchReport = location.pathname.includes('/add-report') && location.pathname.includes('/research');

      let pathname = location.pathname;
      if (!isAuthor) {
        if (isAddMedia) {
          pathname = pathname.replace('/add-media', '');
        } else if (isAddResearchReport) {
          pathname = pathname.replace('/add-report', '');
        }
      }
      const newLocation = { ...location, search: '', pathname: pathname };

      navigate(newLocation);
    }
  }, [isAuthenticated]);

  return <>
    {isAuthenticated && <div className={styles.container}>
      <Header />
      <main>
        <CompanyHeader className='mbXL' symbol={symbol} />
        {invalidSymbol ? <NotFound /> : children }
      </main>
      <Footer />
    </div>}
  </>;
};

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  params: PropTypes.object,
  navigate: PropTypes.func
};


export default withRouter(Layout);
