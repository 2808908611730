import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationTriangle as faExclamationTriangleLight,
  faTrashCan as faTrashCaneLight
} from '@fortawesome/pro-light-svg-icons';

import {
  faCheckCircle as faCheckCircleReg,
  faFilePdf as faFilePdfReg,
  faCloudArrowUp as faCloudArrowUpReg,
  faTimes as faTimesReg
} from '@fortawesome/pro-regular-svg-icons';

import {
  faArrowLeftLong as faArrowLeftLongSolid,
  faUpLong as faUpLongSolid,
  faDownLong as faDownLongSolid,
  faShareFromSquare as faShareFromSquareSolid,
  faChevronDown as faChevronDownSolid,
  faCaretUp as faCaretUpSolid,
  faCirclePlay as faCirclePlaySolid
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faExclamationTriangleLight,
  faTrashCaneLight,
  faCheckCircleReg,
  faFilePdfReg,
  faCloudArrowUpReg,
  faTimesReg,
  faArrowLeftLongSolid,
  faUpLongSolid,
  faDownLongSolid,
  faShareFromSquareSolid,
  faChevronDownSolid,
  faCaretUpSolid,
  faCirclePlaySolid
);
