import api from './api';

export const validateSession = async (token, symbol) => {
  const response = await api.request({
    method: 'get',
    url: '/session-token/',
    params: {
      token,
      symbol
    }
  });
  return response.data;
};

export const logUser = async email => {
  const ipData = await api.request({
    method: 'get',
    url: 'https://api.ipify.org/?format=json'
  });

  const ipAddress = ipData && ipData.data && ipData.data.ip;

  if (!ipAddress) return;

  api.request({
    method: 'get',
    url: '/user/login-log',
    params: {
      email,
      appId: 'GW',
      ipAddress: ipAddress.toString()
    }
  });
};
