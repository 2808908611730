import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './DeleteButton.module.scss';

const DeleteButton = ({ onDeleteClick, accent }) => <>
<div className={cn(styles.container, styles[accent])} onClick={onDeleteClick}>
  <FontAwesomeIcon icon={['fal', 'trash-can']} />
  <div className={styles.text}>Delete</div>
</div>
</>;

DeleteButton.defaultProps = {
  accent: 'green'
};

DeleteButton.propTypes = {
  accent: PropTypes.string,
  onDeleteClick: PropTypes.func
};

export default DeleteButton;
