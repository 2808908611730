import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from '../Title';
import styles from './Label.module.scss';

const Label = ({ title, children, className, hasComplete, isError, completeText }) => (
  <div className={cn(className, styles.container, {
    [styles.error]: isError
  })}>
    <div className={styles.content}>
      <div className={styles.titleSection}>
        <Title className={styles.title} title={title} type='h3' />
        <div className={styles.secondary}>
          {hasComplete && <span className={styles.complete}>
            <FontAwesomeIcon className={styles.icon} icon={['far', 'check']} />
            {completeText}
          </span>}
        </div>
      </div>
      {children && children}
    </div>
  </div>
);

Label.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  hasComplete: PropTypes.bool,
  isError: PropTypes.bool,
  completeText: PropTypes.string
};

export default Label;
