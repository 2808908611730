import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import NotFound from '../../components/NotFound';
import styles from './NotFoundPage.module.scss';


const NotFoundPage = _ => {
    return <div className={styles.container}>
        <Header />
        <NotFound />
        <Footer />
    </div>;
};

export default NotFoundPage;