import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Title.module.scss';

const Title = ({ id, title, type, noMargin, isError, className }) => {
  const HeaderTag = `${type}`;
  const Component = title;
  const isComponent = typeof Component === 'function';

  return <HeaderTag id={id} className={cn(styles[type], className, {
    [styles.noMargin]: noMargin,
    [styles.error]: isError
  })}>
    {isComponent ? <Component /> : title}
  </HeaderTag>;
};

Title.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  isError: PropTypes.bool,
  type: PropTypes.oneOf(['h1', 'h2', 'h3']).isRequired
};

Title.defaultProps = {
  type: 'h1'
};

export default Title;
