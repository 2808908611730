import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { DateBox, TextBox } from 'devextreme-react';
import { formatDate } from 'devextreme/localization';
import { DateTime } from 'luxon';
import { format } from '../../utlis/locale';
import { AuthContext } from '../../context/Auth.context';
import { postResearchReportWithFileUpload } from '../../api/research-report';
import { Button } from '../../components/Button';
import CharacterCount from '../../components/CharacterCount';
import FieldLabel from '../../components/FieldLabel';
import Label from '../../components/Label';
import FileUploader from '../../components/FileUploader';
import TextLink from '../../components/TextLink';
import PreviewResearchReport from '../../components/PreviewResearchReport';
import { withRouter } from '../../components/WithRouter';
import styles from './AddResearchReport.module.scss';

const dataObj = {
  title: ''
};

const dateTimeLabel = { 'aria-label': 'Date Time' };
const nameLabel = { 'aria-label': 'Name' };
const TITLE_MAX_LENGTH = 100;

const allowedFileExtensions = ['application/pdf'];
const maxFileSize = 52428800;
const byteSize = format(maxFileSize, maxFileSize, 'byteSize');
const fileUploaderSubTitle = `(.pdf only) - ${byteSize}`;

const AddResearchReport = ({ navigate }) => {
  const [authState] = useContext(AuthContext);

  const [reportData, setReportData] = useState({ ...dataObj, reportDate: DateTime.local().toMillis(), publishedDate: DateTime.local().toMillis(), publishedTime: DateTime.local().toMillis() });

  const [isEntitled, setEntitled] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [validationErrors, setValidationErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [previewReport, setPreviewReport] = useState(false);

  const user = authState.user;
  const isAuthenticated = authState.isAuthenticated;
  const isAuthor = user.author;

  useEffect(() => {
    if (isAuthenticated) {
      if (isAuthor) {
        setEntitled(true);
      }
    }
  }, [isAuthenticated, isAuthor]);

  useEffect(() => {
    if (!successSubmit) setReportData({ ...dataObj, reportDate: DateTime.local().toMillis(), publishedDate: DateTime.local().toMillis(), publishedTime: DateTime.local().toMillis() });
  }, [successSubmit]);

  const redirectToListings = _ => {
    const newLocation = { ...location, pathname: location.pathname.replace('/add-report', '') };
    navigate(newLocation);
  };

  const formatTimeWithTimezone = (date) => {
    const formattedTime = formatDate(date, 'shortTime');
    const timezoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).formatToParts(date).find(part => part.type === 'timeZoneName').value;
    return `${formattedTime} ${timezoneName}`;
  };

  const handleValueChanged = e => {
    const name = e.component.option('name');
    const isPublishedDate = name === 'publishedDate';
    const isPublishedTime = name === 'publishedTime';
    const isReportDate = name === 'reportDate';

    if (isPublishedDate && e.value === null || isPublishedTime && e.value === null || isReportDate && e.value === null) e.value = DateTime.local().toMillis();

    setReportData(prev => ({
      ...prev,
      [name]: e.value
    }));
  };

  const handleBlur = e => {
    const name = e.component.option('name');
    setReportData(prev => ({
      ...prev,
      [name]: prev[name].trim()
    }));
  };

  const handleCancelClick = _ => {
    redirectToListings();
  };

  const isValidForm = _ => {
    const emptyFields = [];

    if (!reportData.title) emptyFields.push('title');
    if (!reportData.publishedDate) emptyFields.push('publishedDate');
    if (!reportData.publishedTime) emptyFields.push('publishedTime');
    if (!reportData.reportDate) emptyFields.push('reportDate');
    if (!selectedFile) emptyFields.push('file');

    if (emptyFields.length > 0) {
      setValidationErrors(emptyFields);
      return false;
    }

    setValidationErrors([]);
    return true;
  };

  const enablePreviewMode = _ => {
    if (!isValidForm()) {
      return;
    }
    const pDate = DateTime.fromMillis(reportData.publishedDate);
    const publishedDate = pDate.toFormat('MM/dd/yyyy');

    const publishedTime = formatTimeWithTimezone(new Date(reportData.publishedTime));

    const reportD = DateTime.fromMillis(reportData.reportDate);
    const reportDate = reportD.toFormat('MM/dd/yyyy');

    const pData = {
      title: reportData.title,
      publishedDate: publishedDate,
      publishedTime: publishedTime,
      reportDate: reportDate,
      selectedFile: selectedFile
    };
    setPreviewData(pData);
    setPreviewReport(true);
  };

  const exitPreviewMode = _ => {
    setPreviewLoaded(false);
    setPreviewReport(false);
    setSubmitError(false);
  };

  const handleSubmitClick = _ => {
    setIsSubmitting(true);
    setSubmitError(false);

    // Parse the timestamps
    const dateTimeForDate = DateTime.fromMillis(reportData.publishedDate);
    const dateTimeForTime = DateTime.fromMillis(reportData.publishedTime);
    // Extract the date and time parts
    const datePart = dateTimeForDate.toFormat('yyyy-MM-dd');
    const timePart = dateTimeForTime.toFormat('HH:mm:ss');
    // Combine them into the desired format
    const isoString = `${datePart}T${timePart}`;

    const reportDateForDate = DateTime.fromMillis(reportData.reportDate);
    const reportDatePart = reportDateForDate.toFormat('yyyy-MM-dd');

    const postParams = {
      compId: user.companyId,
      userId: user.id,
      title: encodeURI(reportData.title),
      reportDate: reportDatePart,
      publishDate: DateTime.fromISO(isoString)
        .setZone('America/New_York')
        .toFormat('yyyy-MM-dd HH:mm:ss')
    };
    postResearchReportWithFileUpload(postParams, selectedFile)
      .then(data => {
        setIsSubmitting(false);
        setSuccessSubmit(true);
        setReportData({ ...dataObj, publishedDate: DateTime.local().toMillis(), publishedTime: DateTime.local().toMillis(), reportDate: DateTime.local().toMillis() });
        setSelectedFile(null);
        // clear preview
        exitPreviewMode();
      })
      .catch(e => {
        setIsSubmitting(false);
        setSubmitError(e?.response?.data?.message?.replace('java.lang.IllegalArgumentException: ', '') || e?.message || 'Unable to submit research report. Try again');
      });
  };

  return isEntitled
    ? <div className={styles.container}>
      {successSubmit
        ? <>
          <strong>
            Your research report has been successfully submitted. It will be available on our websites a few minutes after your selected Publication Date/Time.
          </strong>
          <p><TextLink onClick={_ => setSuccessSubmit(false)} text='Click here' /> to add another research report.</p>
          <p><TextLink onClick={redirectToListings} text='Click here' /> to go to the research report listing.</p>
        </>
        : previewReport
          ? <>
            <PreviewResearchReport data={previewData} previewLoaded={previewLoaded} setPreviewLoaded={setPreviewLoaded} />
            {submitError && <Label className='mtLg' isError>{submitError}.</Label>}
            <div className={styles.controls}>
              {isSubmitting && <span className={`${styles.loader} mrLg`} />}
              <TextLink text='Cancel' inactive={isSubmitting} onClick={exitPreviewMode} />
              <Button className='mlLg' title='Submit' inactive={!previewLoaded || isSubmitting} onClick={handleSubmitClick} />
            </div>
          </>
          : <>
            <h1>Add Research Report</h1>
            <div>
              <div className={cn(styles.gridCol3, 'mtMed')}>
                <div>
                  <FieldLabel text='Publication Date' />
                  <DateBox
                    name='publishedDate'
                    defaultValue={DateTime.local().toMillis()}
                    inputAttr={dateTimeLabel}
                    type='date'
                    disabled={isSubmitting}
                    min={new Date()}
                    value={reportData.publishedDate}
                    isValid={!validationErrors.includes('publishedDate')}
                    onValueChanged={handleValueChanged}
                  />
                </div>
                <div>
                  <FieldLabel text='Publication Time' />
                  <DateBox
                    name='publishedTime'
                    defaultValue={DateTime.local().toMillis()}
                    inputAttr={dateTimeLabel}
                    type='time'
                    disabled={isSubmitting}
                    value={reportData.publishedTime}
                    displayFormat={formatTimeWithTimezone}
                    isValid={!validationErrors.includes('publishedTime')}
                    onValueChanged={handleValueChanged}
                  />
                </div>
                <div>
                  <FieldLabel text='Report Date' />
                  <DateBox
                    name='reportDate'
                    defaultValue={DateTime.local().toMillis()}
                    inputAttr={dateTimeLabel}
                    type='date'
                    disabled={isSubmitting}
                    value={reportData.reportDate}
                    isValid={!validationErrors.includes('reportDate')}
                    onValueChanged={handleValueChanged}
                  />
                </div>
              </div>
              <div className='mtMed'>
                <FieldLabel text='Title' />
                <TextBox
                  name='title'
                  inputAttr={nameLabel}
                  placeholder='Title'
                  valueChangeEvent='keyup'
                  value={reportData.title}
                  disabled={isSubmitting}
                  maxLength={TITLE_MAX_LENGTH}
                  isValid={!validationErrors.includes('title')}
                  onFocusOut={handleBlur}
                  onValueChanged={handleValueChanged}
                />
                <CharacterCount inputLength={reportData.title.length} total={TITLE_MAX_LENGTH} />
              </div>

              <div className='mtMed'>
                <FieldLabel text='Upload File' />
                <FileUploader
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  allowedFileExtensions={allowedFileExtensions}
                  isValid={!validationErrors.includes('file')}
                  maxFileSize={maxFileSize}
                  disabled={isSubmitting}
                  subTitle={fileUploaderSubTitle}
                />
              </div>
              <div className={styles.controls}>
                <TextLink text='Cancel' inactive={isSubmitting} onClick={handleCancelClick} />
                <Button className='mlLg' title='Preview' onClick={enablePreviewMode} />
              </div>
            </div>
          </>
      }
    </div> : <></>;
};

AddResearchReport.propTypes = {
  navigate: PropTypes.func
};

export default withRouter(AddResearchReport);
