import api from './api';

export async function getMedia(params) {
    const response = await api.request({
        method: 'get',
        url: `/media/list`,
        params: {
            ...params
        },
        isOTC: true
    });
    return response.data;
};

export async function postMedia(params) {
    const requestObj = {
        method: 'post',
        url: `/media/add`,
        params,
        isOTC: true
    };


    const response = await api.request(requestObj);
    return response.data;
};

export async function handleFileUpload(params, file) {
    try {
        const s3RequestParams = {
            method: 'get',
            url: '/media/s3',
            params: {
                compId: params.compId,
                fileName: file.name,
                fileType: file.type
            }
        };

        const s3UrlResponse = await api.request(s3RequestParams);
        const s3URL = s3UrlResponse?.data?.url;
        const s3FileUrl = s3UrlResponse?.data?.fileUrl;

        const s3UploadResponse = await api.request({
            method: 'put',
            url: s3URL,
            headers: {
                'Content-Type': file.type
            },
            data: file,
        });

        const mediaRequestObj = {
            method: 'post',
            url: `/media/add`,
            params: {
                ...params,
                fileUrl: s3FileUrl,
                fileType: file.type
            },
            isOTC: true
        };


        const mediaResponse = await api.request(mediaRequestObj);
        return mediaResponse.data;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw new Error(error);
    }
};

export async function deleteMedia(params) {
    const response = await api.request({
        method: 'delete',
        url: `/media`,
        params,
        isOTC: true
    });
    return response.data;
};
