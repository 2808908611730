import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './DisplayResults.module.scss';

const DisplayResults = ({ className, show, total, text }) => (
  <div className={classNames(styles.component, className)}>
    Displaying {show} of {total} {text || ''}
  </div>
);

DisplayResults.propTypes = {
  className: PropTypes.string,
  show: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  text: PropTypes.string,
};

export default DisplayResults;
