import api from './api';

export async function getResearchReports (params) {
  const response = await api.request({
    method: 'get',
    url: `/research/list`,
    params: {
      ...params
    },
    isOTC: true
  });
  return response.data;
};

export async function postResearchReportWithFileUpload(params, file) {
  let formData = new FormData();
  formData.append('file', file);

  const response = await api.request({
    method: 'post',
    isOTC: true,
    url: `/research/add`,
    params: params,
    data: formData
  });
  return response.data;

};

export async function deleteResearchReport (params) {
  const response = await api.request({
    method: 'delete',
    url: `/research`,
    params,
    isOTC: true
  });
  return response.data;
};
