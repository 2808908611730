import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/Auth.context';
import { ThemeContext } from '../../context/Theme.context';
import { Button } from '../../components/Button';
import ErrorPage from '../../container/ErrorPage';
import { withRouter } from '../../components/WithRouter';
import VideosTable from '../../container/VideosTable';
import DocsTable from '../../container/DocsTable/DocsTable';
import styles from './MediaPage.module.scss';


const MediaPage = ({ params }) => {
    const [themeState] = useContext(ThemeContext);
    const [authState] = useContext(AuthContext);
    const [videosParams, setVideosParams] = useState(null);
    const [videosData, setVideosData] = useState(null);
    const [docsData, setDocsData] = useState(null);
    const [showErrorPage, setErrorPage] = useState(false);
    const [mediaError, setMediaError] = useState(null);
    const [reloading, setReloading] = useState(false);
    const accentColor = themeState.color;
    const symbol = params?.symbol;
    const user = authState.user;
    const isAuthor = user.author;
    const isReady = themeState.isReady;

    return showErrorPage ? <ErrorPage /> : <div className={styles.container}>
        {isReady && <>
            {isAuthor && <div className={styles.btnContainer}>
                <Link to={ROUTES.addMedia.replace(':symbol', symbol)}>
                    <Button title='Add Video or Presentation' color={accentColor} />
                </Link>
            </div>}
            <div className='mtMed'>
                <VideosTable
                    accent={accentColor}
                    data={videosData}
                    symbol={symbol}
                    editable={isAuthor}
                />
            </div>
            <div className='mtXXL'>
                <DocsTable
                    accent={accentColor}
                    data={docsData}
                    symbol={symbol}
                    editable={isAuthor}
                />
            </div>
        </>}
    </div>;
};

MediaPage.propTypes = {
    params: PropTypes.object,
};

export default withRouter(MediaPage);