import React, { useContext, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import DataGrid, {
  Column
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { deleteResearchReport, getResearchReports } from '../../api/research-report';
import { paginate, dynamicSort } from '../../utlis/helper';
import { AuthContext } from '../../context/Auth.context';
import { DeleteButton } from '../../components/DeleteButton';
import { DisplayResults } from '../../components/DisplayResults';
import { Loading } from '../../components/Loading';
import { TextLink } from '../../components/TextLink';
import { Button } from '../../components/Button';
import { Label } from '../../components/Label';
import { Outline } from '../../components/Outline';
import { More } from '../../components/More';
import styles from './ResearchReportsTable.module.scss';

const ASC = 'ASC';
const DESC = 'DESC';
const FIRST_PAGE_NUM = 1;
const PAGE_SIZE = 10;

const getSortDataType = (property) => {
  if (property === 'createdTimestamp') return 'number';
  return 'string';
};

const DELETE_CONFIRM_TEXT = 'You have elected to delete your research report. It will no longer appear on any OTC Markets Group Inc. products. Do you still want to delete?';

const ResearchReportsTable = ({ accent, symbol, editable }) => {
  const dataGridRef = useRef(null);
  const [authState] = useContext(AuthContext);

  const [docsParams, setDocsParams] = useState(null);
  const [docsData, setDocsData] = useState(null);
  const [currentDocsDataSet, setCurrentDocsDataSet] = useState(null);
  const [isReloading, setReloading] = useState(false);
  const [docsError, setDocsError] = useState(null);
  const [popupWidth, setPopupWidth] = useState('40vw');

  // delete vars
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [deleteDialogInactive, setDeleteDialogInactive] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [successDelete, setSuccessDelete] = useState(false);

  const compId = authState.user.companyId;
  const user = authState.user;
  const hasMorePages = docsData?.totalPages > docsData?.currentPage;

  useEffect(() => {
    setDocsParams({ compId });
  }, [symbol]);

  useEffect(() => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearSorting();
    }
  }, [symbol]);

  useEffect(() => {
    if (docsParams !== undefined && docsParams !== null) loadDocs();
  }, [docsParams]);

  useEffect(() => {
    updatePopupWidth(); // Set initial width
    window.addEventListener('resize', updatePopupWidth); // Update width on resize
    return () => window.removeEventListener('resize', updatePopupWidth);
  }, []);

  const updatePopupWidth = () => {
    if (window.innerWidth <= 1200) {
      setPopupWidth('80vw');
    } else {
      setPopupWidth('40vw');
    }
  };

  const handleDeleteShow = data => {
    setDeleteDialogVisible(true);
    setDeleteDoc(data);
  };

  const handleDeleteHide = _ => {
    setDeleteDialogVisible(false);
    setDeleteError(null);
    if (successDelete) {
      setDocsParams({ compId });
      loadDocs();
    }
  };

  const handleDeleteHidden = _ => {
    if (successDelete) {
      setSuccessDelete(false);
    }
  };

  const handleConfirmDeleteDoc = _ => {
    const deleteParams = {
      id: deleteDoc.id,
      userId: user.id
    };

    setDeleteDialogInactive(true);

    deleteResearchReport(deleteParams)
      .then(_ => {
        setDeleteDialogInactive(false);
        setSuccessDelete(true);
      })
      .catch(e => {
        setDeleteDialogInactive(false);
        setDeleteError('Error deleting file. Try again.');
      });
  };

  const loadDocs = _ => {
    if (docsData) setReloading(true);

    getResearchReports(docsParams)
      .then(data => {
        const totalResults = data?.length;
        const totalPages = totalResults ? Math.ceil(totalResults / PAGE_SIZE) : 0;
        const newData = paginate(data, PAGE_SIZE, FIRST_PAGE_NUM);

        const dataObj = {
          results: data,
          totalPages,
          totalResults,
          currentPage: FIRST_PAGE_NUM
        };
        setDocsData(dataObj);
        setCurrentDocsDataSet(newData);
        setReloading(false);
      })
      .catch(error => {
        setReloading(false);
        setDocsError(error?.response?.data?.error || 'Error fetching Research Reports.');
      });
  };

  // paging
  const handleMoreClick = _ => {
    setReloading(true);
    const newPageData = paginate(docsData.results, PAGE_SIZE, docsData.currentPage + 1); // new page
    const newData = [...currentDocsDataSet, ...newPageData];
    setDocsData(prevData => {
      return { ...prevData, currentPage: docsData.currentPage + 1 };
    });
    setCurrentDocsDataSet(newData);
    setReloading(false);
  };

  // sorting
  const handleCellClick = e => {
    if (e?.rowType === 'header' && (e?.column?.allowSorting)) {
      setReloading(true);
      const sortBy = e.column.dataField;
      let sortOn = ASC;
      if (docsData?.sortBy === sortBy && docsData.sortOn === ASC) sortOn = DESC;

      // sort entire result set
      let newData = [...docsData.results];
      newData = newData.sort(dynamicSort(sortBy, getSortDataType(sortBy), sortOn));

      // get first page
      const newPageData = paginate(newData, PAGE_SIZE, FIRST_PAGE_NUM);
      setDocsData(prevData => {
        return { ...prevData, results: newData, currentPage: FIRST_PAGE_NUM, sortOn, sortBy };
      });
      setCurrentDocsDataSet(newPageData);
      setReloading(false);
    }
  };

  const sortStringsConsideringCulture = _ => {
    return;
  };

  const tableColumns = [
    {
      dataField: 'title',
      allowSorting: true,
      sortingMethod: sortStringsConsideringCulture,
      cellRender: item => {
        const isClickable = item.data.status === 'ACTIVE' || item.data.status === 'SCHEDULED';

        return <div className={cn(styles.colWrap, styles[accent], {
          [styles.link]: isClickable
        })} onClick={_ => isClickable ? handleDocClick(item.data) : null}>{item.value}</div>;
      }
    },
    {
      caption: 'status',
      dataField: 'status',
      allowSorting: false,
      cellRender: item => {
        let statusValue = item.value;

        if (statusValue === 'HOLD' || statusValue === 'BLOCKED') statusValue = 'DENIED';

        return <div className={styles[`status${statusValue}`]}>{statusValue}</div>;
      },
      visible: editable
    },
    {
      caption: 'Published',
      dataField: 'publishDate',
      allowSorting: true,
      sortingMethod: sortStringsConsideringCulture,
      cellRender: item => item.data.publishDate && <>{DateTime.fromISO(item.data.publishDate)
        .toLocal()
        .toFormat(editable ? 'LLL dd, yyyy hh:mm a ZZZZ' : 'LLL dd, yyyy')}<br />{item.data.reportUrl && item.data.publisherName}</>
    },
    {
      caption: 'report date',
      dataField: 'reportDate',
      allowSorting: true,
      sortingMethod: sortStringsConsideringCulture,
      cellRender: item => item.data.reportDate && <>{DateTime.fromISO(item.data.reportDate)
        .toLocal()
        .toFormat('LLL dd, yyyy')}</>
    },
    {
      caption: 'Created',
      dataField: 'createdDate',
      allowSorting: true,
      alignment: 'left',
      sortingMethod: sortStringsConsideringCulture,
      visible: editable,
      cellRender: item => item.data.createdDate && <>{DateTime.fromISO(item.data.createdDate)
        .toLocal()
        .toFormat('LLL dd, yyyy hh:mm a ZZZZ')}<br />{item.data.createdBy}</>
    },
    {
      caption: 'Action',
      allowSorting: false,
      cellRender: item => !item.data.reportUrl && item.data.status !== 'DELETED' && item.data.id !== null && <DeleteButton title={item.data.title} accent={accent} onDeleteClick={_ => handleDeleteShow(item.data)} />,
      visible: editable
    }
  ];

  const handlePreparing = e => {
    if (e.target === 'header') {
      e.target.visible = false;
    }
  };

  const handleDocClick = item => {
    // use report URL if exists
    if (item.reportUrl) {
      window.open(item.reportUrl);
    } else {
      window.open(`/research/content?symbol=${symbol}&id=${item.id}`);
    }
  };

  return <div className={styles.container}>
    <Outline mode='heading' accent={accent}>
      Research Reports
    </Outline>
    <Loading loaded={!!currentDocsDataSet} reloading={isReloading} error={docsError}>
      <DataGrid
        className={`${currentDocsDataSet?.length === 0 ? 'hide-scroll' : ''}`}
        onContextMenuPreparing={handlePreparing}
        ref={dataGridRef}
        dataSource={currentDocsDataSet}
        paging={{ enabled: false }}
        columnAutoWidth
        onCellClick={handleCellClick}
        loadPanel={{ enabled: false }}
        noDataText='No Research Reports available.'
      >
        {tableColumns.map((column, i) => (
          <Column key={`column${i}`} {...column} />
        ))}
      </DataGrid>
      <More onClick={handleMoreClick} disabled={!hasMorePages} />
      {currentDocsDataSet?.length > 0 && <DisplayResults show={currentDocsDataSet?.length || 0} total={docsData?.totalResults} text={'Research Reports'} />}
    </Loading>
    <Popup
      visible={deleteDialogVisible}
      dragEnabled={false}
      title={deleteDoc?.title}
      hideOnOutsideClick
      showCloseButton
      position='center'
      width={popupWidth}
      height='auto'
      onHidden={handleDeleteHidden}
      onHiding={handleDeleteHide}>
      {successDelete ? <div>
        <div className={styles.confrmMsg}>
          <FontAwesomeIcon className='mrSm' icon={['far', 'check-circle']} />
          {deleteDoc?.title} has successfully been deleted.
        </div>
        <div className={styles.dialogControls}>
          <Button title='OK' onClick={_ => setDeleteDialogVisible(false)} />
        </div>
      </div> : <>
        <div>
          <h3>{DELETE_CONFIRM_TEXT}</h3>
        </div>
        {deleteError && <Label className='mbLg' isError>{deleteError}</Label>}
        <div className={styles.dialogControls}>
          <TextLink text='Cancel' inactive={deleteDialogInactive} onClick={_ => setDeleteDialogVisible(false)} />
          <Button className='mlLg' title='Delete' inactive={deleteDialogInactive} onClick={handleConfirmDeleteDoc} />
        </div></>}
    </Popup>
  </div>;
};

ResearchReportsTable.defaultProps = {
  accent: 'green'
};

ResearchReportsTable.propTypes = {
  accent: PropTypes.string,
  symbol: PropTypes.string,
  editable: PropTypes.bool
};

export default ResearchReportsTable;
