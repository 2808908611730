import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import cn from 'classnames';
import { filesize } from 'filesize';
import { VariableSizeList as List } from "react-window";
import { asyncMap } from "@wojtekmaj/async-array-utils";
import PropTypes from 'prop-types';
import StaticValue from '../StaticValue';
import styles from './PreviewResearchReport.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const width = 500;
const height = width * 1.5;

function Row({ index, style }) {
  function onPageRenderSuccess(page) {
    console.log(`Page ${page.pageNumber} rendered`);
  }

  return (
    <div style={style}>
      <Page
        loading=''
        renderTextLayer={false}
        renderAnnotationLayer={false}
        // only for debugging onRenderSuccess={onPageRenderSuccess}
        key={`page_${index + 1}`}
        pageIndex={index}
        width={width}
      />
    </div>
  );
}

const PreviewResearchReport = ({ data, previewLoaded, setPreviewLoaded }) => {
  const [pdf, setPdf] = useState(null);
  const [pageViewports, setPageViewports] = useState(null);

  useEffect(() => {
    setPageViewports(null);

    if (!pdf) {
      return;
    }

    (async () => {
      const pageNumbers = Array.from(new Array(pdf.numPages)).map(
        (_, index) => index + 1
      );

      const nextPageViewports = await asyncMap(pageNumbers, (pageNumber) =>
        pdf.getPage(pageNumber).then((page) => page.getViewport({ scale: 1 }))
      );

      setPageViewports(nextPageViewports);
    })();
  }, [pdf]);


  const onDocumentLoadSuccess = (nextPdf) => {
    setPdf(nextPdf);
    setPreviewLoaded(true);
  }

  const getPageHeight = (pageIndex) => {
    if (!pageViewports) {
      console.log("getPageHeight() called too early");
      return
    }

    const pageViewport = pageViewports[pageIndex];
    const scale = width / pageViewport.width;
    const actualHeight = pageViewport.height * scale;

    return actualHeight;
  }

  const onDocumentLoadError = (e) => {
    console.log(e)
    setPreviewLoaded(true);
  };

  return <div className={styles.container}>
    <h1>Add Research Report</h1>
    <div className={cn(styles.content, 'mtMed')}>
      <div style={{ 'flex': 1 }}>
        <div className={cn(styles.gridCol3)}>
          <StaticValue labelText='Publication Date' value={data.publishedDate} />
          <StaticValue labelText='Publication Time' value={data.publishedTime} />
          <StaticValue labelText='Report Date' value={data.reportDate} />
        </div>
        <StaticValue labelText='Title' value={data.title} />

      </div>
      {!!data.selectedFile && <div style={{ 'flex': 1 }}>
        <StaticValue labelText={`${data?.selectedFile?.name} - ${filesize(data?.selectedFile?.size, { base: 10 })}`} />

        {!previewLoaded && <div><StaticValue labelText='Loading Report Preview' /><span className={`${styles.loader} mrLg`} /></div>}
        <Document
          loading=''
          file={data.selectedFile}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        >
          {pdf && pageViewports ? (
            <List
              className={styles.noHorizontalScroll}
              width={width}
              height={height}
              estimatedItemSize={height}
              itemCount={pdf.numPages}
              itemSize={getPageHeight}
            >
              {Row}
            </List>
          ) : null}
        </Document>

      </div>
      }
    </div>

  </div>;
};

PreviewResearchReport.propTypes = {
  data: PropTypes.object
};

export default PreviewResearchReport;
