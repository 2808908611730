export const footerLinks = [
  {
    link: 'Terms of Service',
    href: 'https://www.otcmarkets.com/terms-of-service'
  },
  {
    link: 'Linking Terms',
    href: 'https://www.otcmarkets.com/linking-terms'
  },
  {
    link: 'Trademarks',
    href: 'https://www.otcmarkets.com/trademarks'
  },
  {
    link: 'Privacy Statement',
    href: 'https://www.otcmarkets.com/privacy-statement'
  },
  {
    link: 'Code of Conduct',
    href: 'https://www.otcmarkets.com/corporate-code-of-conduct'
  },
  {
    link: 'Risk Warning',
    href: 'https://www.otcmarkets.com/risk-warning'
  },
  {
    link: 'Supported Browsers',
    href: 'https://www.otcmarkets.com/supported-browsers'
  }
];
