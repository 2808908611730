import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Thumbnail.module.scss';

const ImageWithFallback = ({ src, fallback, alt }) => {
  const handleError = (e) => {
    e.target.src = fallback;
  };

  return <img className={styles.thumbnail} src={src} onError={handleError} alt={alt} />;
};

ImageWithFallback.propTypes = {
  src: PropTypes.string,
  fallback: PropTypes.string,
  alt: PropTypes.string
};

const Thumbnail = ({ className, imageSrc, type, altText, iconColor }) => {
  const isVideo = type === 'video';

  return <div className={cn(styles.container, className)}>
    <ImageWithFallback
      src={imageSrc}
      fallback='https://s3.amazonaws.com/content.otcmarkets.test.otc/media-service/defaultImage.png'
      alt={altText || type}
    />
    {isVideo && <FontAwesomeIcon className={cn(styles.playIcon, styles[iconColor])} icon={['fas', 'circle-play']} size='4x' />}
  </div>;
};

Thumbnail.defaultProps = {
  iconColor: 'green'
};

Thumbnail.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  altText: PropTypes.string,
  type: PropTypes.string,
  iconColor: PropTypes.string
};

export default Thumbnail;
