import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './TextLink.module.scss';

const TextLink = (props) =>
  <a href={props.href && props.href}
    target={props.target && `_${props.target}`}
    rel='noopener'
    id={props.id}
    className={classNames(props.className, {
    [styles.link]: true,
    [styles.active]: !props.inactive,
    [styles.inactive]: props.inactive,
    [styles.small]: props.size === 'small'
  })} onClick={props.inactive ? undefined : props.onClick}>
    {props.text || props.children}
  </a>;

TextLink.propTypes = {
  id: PropTypes.string,
  /** Class name of the input, for custom designs */
  className: PropTypes.string,
  /** On click method */
  onClick: PropTypes.func,
  /** Button text */
  text: PropTypes.string,
  href: PropTypes.string,
  /** TextLink content */
  children: PropTypes.node,
  /** Button state: active by default */
  inactive: PropTypes.bool,
  /** Target for link */
  target: PropTypes.oneOf(['blank', 'parent', 'self', 'top']),
  /* Size of input font */
  size: PropTypes.oneOf(['small', 'default'])
};

export default TextLink;
